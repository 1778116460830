<template>
  <div id="app">
    <AppTitleHeader v-if="!$route.meta.hide_appbar" textClass="text-challenge-mode" data-aos="fade-in" data-aos-delay="500" />
    <transition :name="$store.state.pageTransition.name" :mode="$store.state.pageTransition.mode" v-on:after-enter="afterEnter" v-on:after-leave="afterLeave">
      <router-view class="transition" />
    </transition>
  </div>
</template>
<script>
import Store from './store/index';
import AppTitleHeader from '@/components/AppTitleHeader.vue';

export default {
  components: { AppTitleHeader },
  methods: {
    afterEnter: () => {
      window.scrollTo(0, 0);
    },
    afterLeave: () => {
      Store.commit('setPageTransition', 'default');
    }
  }
};
</script>
<style lang="scss">
@import '@/assets/custom-vars/_colors.scss';
html,
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: $gradient-literature;
  background-color: $literature;
  height: 100%;
}
$duration: 0.5s;
.transition {
  overflow: hidden;
}
.router-view,
.router-view-back {
  &-enter-active,
  &-leave-active {
    position: fixed;
    width: 100%;
    background: white;
    min-height: 100vh;
    top: 0;
  }
}
// router view
.router-view-enter-active {
  transition: transform $duration ease-in-out;
  z-index: 2;
  transform: translateX(100%);
}
.router-view-enter-to {
  z-index: 2;
  transform: translateX(0%);
}
.router-view-leave-active {
  z-index: -1;
}
.router-view-leave-to {
  z-index: -1;
}
// router view back

.router-view-back-leave-active {
  transition: transform $duration ease-in-out;
  z-index: 2;
  transform: translateX(0%);
}
.router-view-back-leave-to {
  z-index: 2;
  transform: translateX(100%);
}
</style>
