import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:lang',
    name: 'profile',
    component: () => import('@/views/Home.vue'),
    meta: {
      hide_appbar: true
    }
  },
  {
    path: '/:lang/profile/:profile_id',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      hide_appbar: true
    }
  },
  {
    path: '/:lang/challenge/:id/host/',
    name: 'challenge-host',
    component: () => import('@/views/Challenge.vue'),
    meta: {
      as: 'host'
    }
  },
  {
    path: '/:lang/challenge/:id/join/',
    name: 'challenge-join',
    component: () => import('@/views/Challenge.vue'),
    meta: {
      as: 'join'
    }
  },
  {
    path: '/:lang/challenge/:id/host/setup',
    name: 'challenge-host-setup',
    component: () => import('@/views/ChallengeSetup.vue'),
    meta: {
      as: 'host'
    }
  },
  {
    path: '/:lang/challenge/:id/join/setup',
    name: 'challenge-join-setup',
    component: () => import('@/views/ChallengeSetup.vue'),
    meta: {
      as: 'join'
    }
  },
  {
    path: '/:lang/challenge/:id/host/share',
    name: 'challenge-host-share',
    component: () => import('@/views/ChallengeShare.vue'),
    meta: {
      as: 'host'
    }
  },
  {
    path: '/:lang/challenge/:id/host/done',
    name: 'challenge-host-done',
    component: () => import('@/views/ChallengeDone.vue'),
    meta: {
      as: 'host'
    }
  },
  {
    path: '/:lang/challenge/:id/join/done',
    name: 'challenge-join-done',
    component: () => import('@/views/ChallengeDone.vue'),
    meta: {
      as: 'join'
    }
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  let { lang, profile_id } = to.params;
  console.log(to);
  if (!['en', 'pt-BR', 'fr', 'es'].includes(lang)) {
    if (profile_id) {
      next({ path: '/en/profile/' + profile_id });
    }
  }

  if (router.i18n.locale !== lang) {
    router.i18n.locale = lang;
  }

  return next();
});

export default router;
