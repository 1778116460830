<template>
  <div class="app-title-header d-flex align-items-center px-2 legend mt-3 bg-white font-weight-bold">
    <div class="app-logo bg-white" :style="{ backgroundImage: logoImg }"></div>
    <p :class="[textClass]">
      {{ $t('app.title') }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    textClass: { type: String, required: true }
  },
  computed: {
    logoImg() {
      return `url(${require('@/assets/images/qotd-512px-white-bg-2.jpg')})`;
    }
  }
};
</script>
<style lang="scss" scoped>
.app-title-header {
  left: 1.5rem;
  position: absolute;
  height: 21px;
  z-index: 3;
  border-radius: 10px;
  > p {
    margin: 0px;
    margin-left: 21px;
  }
  > .app-logo {
    background-color: white;
    position: absolute;
    width: 34px;
    height: 34px;
    transform: translateX(-17px);
    background-position: center center;
    background-size: 28px 28px;
    background-repeat: no-repeat;
    border-radius: 50%;
  }
}
</style>
