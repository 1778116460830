import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    challenge: {
      nickname: false,
      session_data: false
    },
    pageTransition: {
      name: 'none',
      mode: 'in-out'
    }
  },
  mutations: {
    setNickname(state, nickname) {
      state.challenge.nickname = nickname;
    },
    setSession(state, session) {
      state.challenge.session_data = session;
    },
    setPageTransition(state, value) {
      if ('default' === value) {
        Vue.set(state, 'pageTransition', {
          name: 'router-view',
          mode: 'in-out'
        });
      }
      if ('back' === value) {
        Vue.set(state, 'pageTransition', {
          name: 'router-view-back',
          mode: ''
        });
      }
    }
  }
});
